require("./src/assets/styles/font.css")

const LogRocket = require("logrocket")
const setupLogRocketReact = require("logrocket-react")

exports.onInitialClientRender = () => {
  if (typeof window !== "undefined" && process.env.NODE_ENV === "production") {
    LogRocket.init("9j68ze/lasoft")
    setupLogRocketReact(LogRocket)
  }
}
